import { patch, get, post, del } from 'modules/api';

/**
 * Get list of all allergens
 *
 * @returns {Promise}
 */
export const fetchAllergensList = () => get('products/allergen/list');

/**
 * Create a product
 *
 * @param {number} _.typeId
 * @param {number} _.farmId
 * @returns {Promise}
 */
export const createProduct = ({ typeId, farmId }) =>
    post('products/', { type: typeId, farm: farmId });

/**
 * Update product
 * @param {number} productId
 * @param {number} offerId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateProductOffer = (productId, offerId, payload) =>
    patch(`products/${productId}/offers/${offerId}`, payload);

/**
 *
 * @param {number} productId
 * @param {number} offerId
 * @returns {Promise}
 */
export const deleteProductOffer = (productId, offerId) =>
    del(`products/${productId}/offers/${offerId}`);

/**
 *
 * @param {number} productId
 * @param {Object} payload
 * @returns {Promise}
 */
export const createProductOffer = (productId, payload) =>
    post(`products/${productId}/offers`, payload);

/**
 *
 * @param {number} productId
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateProduct = (productId, payload) => patch(`products/${productId}`, payload);

/**
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const fetchProductById = productId => get(`products/${productId}`);

/**
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const deleteProduct = productId => del(`products/${productId}`);

/**
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const duplicateProduct = productId => post(`products/${productId}/duplicate`);

/**
 *
 * @param {string} searchString
 * @param {number} distributionId
 * @returns {Promise}
 */
export const fetchSearchProduct = (searchString, distributionId) =>
    get(`products/search/${searchString}?distrib=${distributionId}`);
